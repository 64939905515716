var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-agreement" },
    [
      _c(
        "v-container",
        { staticClass: "relative" },
        [
          _c("h1", [_vm._v("Attiva Convenzione: Registrazione Gala Card")]),
          _c("h4", [
            _vm._v(
              " Completa questi campi per finalizzare la registrazione, ci vorranno pochi istanti per essere abilitato alla convenzione. "
            )
          ]),
          _c(
            "v-stepper",
            {
              staticClass: "pa-2 my-4",
              attrs: { elevation: "0", outlined: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 1, step: "1" } },
                    [_vm._v(" Gala Card ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 2, step: "2" } },
                    [_vm._v(" Dati di domicilio ")]
                  ),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "3" } }, [
                    _vm._v(" Account e privacy ")
                  ])
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", lg: "4" } },
                            [
                              _c("img", {
                                staticClass: "card-convenzione-img",
                                attrs: {
                                  src: "/img_layout/card-convenzione.png"
                                }
                              })
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8", lg: "8" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  model: {
                                    value: _vm.isValid,
                                    callback: function($$v) {
                                      _vm.isValid = $$v
                                    },
                                    expression: "isValid"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "primary",
                                      label: "Codice Gala Card",
                                      outlined: "",
                                      dense: "",
                                      rules: _vm.requiredRules,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.fidelityCard,
                                      callback: function($$v) {
                                        _vm.fidelityCard = $$v
                                      },
                                      expression: "fidelityCard"
                                    }
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-2",
                                              attrs: {
                                                dense: "",
                                                block: "",
                                                large: "",
                                                elevation: "0",
                                                to: {
                                                  name: "RegistrationUser",
                                                  path: "/registration-user"
                                                }
                                              }
                                            },
                                            [_vm._v("Indietro")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-2",
                                              attrs: {
                                                dense: "",
                                                block: "",
                                                large: "",
                                                color: "primary",
                                                elevation: "0"
                                              },
                                              on: { click: _vm.findUser }
                                            },
                                            [_vm._v("Conferma")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c("UserData", {
                        attrs: {
                          userData: _vm.userData,
                          supportData: _vm.supportData,
                          hasFidelityCard: true,
                          askFirstName: _vm.askFirstName,
                          askLastName: _vm.askLastName,
                          askFiscalCode: _vm.askFiscalCode,
                          isRegisterWithCard: _vm.isRegisterWithCard,
                          isFiscalCode: _vm.isFiscalCode,
                          registrationType: _vm.registrationType,
                          isSocialLogin: _vm.isSocialLogin
                        },
                        on: {
                          "update:userData": function($event) {
                            _vm.userData = $event
                          },
                          "update:user-data": function($event) {
                            _vm.userData = $event
                          },
                          "update:supportData": function($event) {
                            _vm.supportData = $event
                          },
                          "update:support-data": function($event) {
                            _vm.supportData = $event
                          },
                          "update:isFiscalCode": function($event) {
                            _vm.isFiscalCode = $event
                          },
                          "update:is-fiscal-code": function($event) {
                            _vm.isFiscalCode = $event
                          },
                          back: function($event) {
                            return _vm.goToPreviousStep(true)
                          },
                          submit: _vm.onUserDataSubmit
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c("UserPasswordAndPrivacy", {
                        staticClass: "mt-5",
                        attrs: {
                          userData: _vm.userData,
                          supportData: _vm.supportData,
                          response: _vm.response
                        },
                        on: {
                          "update:userData": function($event) {
                            _vm.userData = $event
                          },
                          "update:user-data": function($event) {
                            _vm.userData = $event
                          },
                          "update:supportData": function($event) {
                            _vm.supportData = $event
                          },
                          "update:support-data": function($event) {
                            _vm.supportData = $event
                          },
                          back: _vm.goToPreviousStep,
                          submit: _vm.registerUser
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }